<template>
  <AppLayout>
    <main>
      <div
        class="
          page-header
          pb-10
          page-header-dark
          bg-gradient-primary-to-secondary
        "
      >
        <div class="container-fluid">
          <div class="page-header-content">
            <h1 class="page-header-title">
              <div class="page-header-icon"><i data-feather="filter"></i></div>
              <span>New Conveyance</span>
            </h1>
            <div class="page-header-subtitle ml-2">Create New Conveyance</div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-n10">
        <div class="card mb-4">
          <div class="card-header">New Conveyance</div>
          <div class="card-body">
            <div class="form">
              <form @submit.prevent="handleSubmit()">
                <div class="form-group">
                  <label for="name">Conveyance Name</label
                  ><input
                    class="form-control"
                    id="name"
                    type="text"
                    v-model="data.name"
                    placeholder="Enter Conveyance Name"
                  />
                </div>

                <button v-if="loading" class="btn btn-primary disabled">
                  <div
                    class="spinner-border spinner-border-sm mx-1 text-light"
                    role="status"
                  ></div>
                  Loading...
                </button>
                <button v-else type="submit" class="btn btn-primary">
                  Create {{ data.conveyance }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>


<script>
import AppLayout from "@/layouts/AppLayout.vue";
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "@/stores/admin";

export default {
  name: "New Conveyance",
  components: { AppLayout },
  data() {
    return {
      data: {
        name: "",
      },
    };
  },
  computed: {
    ...mapState(useAdminStore, ["loading"]),
  },

  methods: {
    ...mapActions(useAdminStore, ["createConveyance"]),
    async handleSubmit() {
      const res = await this.createConveyance(this.data);
      if (res == "success") {
        this.$router.push("/conveyance");
      }
    },
  },

  created() {},
};
</script>